module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Animal Crossing: New Horizons","short_name":"acnh","start_url":"/","description":"The fan-made website of animal crossing: new horizons","orientation":"any","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/assets/images/page/acnh-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"03c281483816cf11a6624dedc9067c7a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Animal Crossing: New Horizons","short_name":"ACNH","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/assets/images/page/acnh-icon.png","localize":[{"start_url":"/zh-hans/","lang":"zh-hans","name":"集合啦！动物森友会","short_name":"动物森友会","description":"集合啦！动物森友会"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"03c281483816cf11a6624dedc9067c7a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
