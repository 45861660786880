// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blathers-museum-fish-js": () => import("./../src/pages/blathers-museum/fish.js" /* webpackChunkName: "component---src-pages-blathers-museum-fish-js" */),
  "component---src-pages-blathers-museum-fossil-js": () => import("./../src/pages/blathers-museum/fossil.js" /* webpackChunkName: "component---src-pages-blathers-museum-fossil-js" */),
  "component---src-pages-blathers-museum-index-js": () => import("./../src/pages/blathers-museum/index.js" /* webpackChunkName: "component---src-pages-blathers-museum-index-js" */),
  "component---src-pages-blathers-museum-sea-creature-js": () => import("./../src/pages/blathers-museum/sea-creature.js" /* webpackChunkName: "component---src-pages-blathers-museum-sea-creature-js" */),
  "component---src-pages-celeste-handbook-js": () => import("./../src/pages/celeste-handbook.js" /* webpackChunkName: "component---src-pages-celeste-handbook-js" */),
  "component---src-pages-gulliver-souvenir-js": () => import("./../src/pages/gulliver-souvenir.js" /* webpackChunkName: "component---src-pages-gulliver-souvenir-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isabelle-villager-js": () => import("./../src/pages/isabelle-villager.js" /* webpackChunkName: "component---src-pages-isabelle-villager-js" */),
  "component---src-pages-kk-album-js": () => import("./../src/pages/kk-album.js" /* webpackChunkName: "component---src-pages-kk-album-js" */),
  "component---src-pages-leif-garden-js": () => import("./../src/pages/leif-garden.js" /* webpackChunkName: "component---src-pages-leif-garden-js" */),
  "component---src-pages-pascal-market-js": () => import("./../src/pages/pascal-market.js" /* webpackChunkName: "component---src-pages-pascal-market-js" */),
  "component---src-pages-redd-artwork-js": () => import("./../src/pages/redd-artwork.js" /* webpackChunkName: "component---src-pages-redd-artwork-js" */),
  "component---src-pages-saharah-floorwall-floor-js": () => import("./../src/pages/saharah-floorwall/floor.js" /* webpackChunkName: "component---src-pages-saharah-floorwall-floor-js" */),
  "component---src-pages-saharah-floorwall-index-js": () => import("./../src/pages/saharah-floorwall/index.js" /* webpackChunkName: "component---src-pages-saharah-floorwall-index-js" */),
  "component---src-pages-saharah-floorwall-rug-js": () => import("./../src/pages/saharah-floorwall/rug.js" /* webpackChunkName: "component---src-pages-saharah-floorwall-rug-js" */),
  "component---src-pages-zipper-gift-js": () => import("./../src/pages/zipper-gift.js" /* webpackChunkName: "component---src-pages-zipper-gift-js" */)
}

