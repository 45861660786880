const CSS_VAR_PREFIX = "acnh"

const INIT_COLOR_MODE_PROP = "--initial-color-mode"

const LS_I18N_LNG_KEY = "acnh-i18n-lng"

const LS_COLOR_MODE_KEY = "acnh-color-mode"

const DEFAULT_LNG = "zh-hans"

const I18N_LANGUAGES = [
  {
    language: "English",
    code: "en",
  },
  {
    language: "中文 (简体)",
    code: "zh-hans",
  },
  {
    language: "中文 (繁體)",
    code: "zh-hant",
  },
  {
    language: "日本語",
    code: "ja",
  },
  {
    language: "한국어",
    code: "ko",
  },
]

const SPEC_LNGS_MAP = {
  "zh-cn": "zh-hans",
  "zh-tw": "zh-hant",
}
const SHORT_HAND_LNGS = ["en", "ja", "ko"]

const DIY_CARD_COLORS = {
  red: {
    bg: "#D8595A",
    fill: "#d84555",
    border: "#F4F0E2",
    icon: "#9C1A19",
  },
  yellow: {
    bg: "#E7DA77",
    fill: "#e4c568",
    border: "#F4F0E2",
    icon: "#796D22",
  },
  green: {
    bg: "#95D57C",
    fill: "#81cc6b",
    border: "#F4F0E2",
    icon: "#2F5A26",
  },
  lightBlue: {
    bg: "#BAD5EB",
    fill: "#afc8dc",
    border: "#F4F0E2",
    icon: "#3D4A8C",
  },
  brick: {
    bg: "#BC9E6E",
    fill: "#a6895a",
    border: "#F4F0E2",
    icon: "#52361F",
  },
  lightGray: {
    bg: "#B9BABE",
    fill: "#aeafb3",
    border: "#F4F0E2",
    icon: "#505055",
  },
  pink: {
    bg: "#F9B9BA",
    fill: "#f8aeac",
    border: "#F4F0E2",
    icon: "#C33D3E",
  },
  beige: {
    bg: "#E5DAAC",
    fill: "#ddd096",
    border: "#F4F0E2",
    icon: "#785737",
  },
  brown: {
    bg: "#94693C",
    fill: "#8b5f39",
    border: "#F4F0E2",
    icon: "#D2BF9A",
  },
  orange: {
    bg: "#F6C568",
    fill: "#e7ba67",
    border: "#F4F0E2",
    icon: "#8E671C",
  },
  darkGray: {
    bg: "#6A6A6E",
    fill: "#636368",
    border: "#F4F0E2",
    icon: "#C4C6C7",
  },
  white: {
    bg: "#E8E8E6",
    fill: "#dbdcdb",
    border: "#F4F0E2",
    icon: "#83837A",
  },
  gold: {
    bg: "#D6C574",
    fill: "#c9b96e",
    border: "#AE9846",
    icon: "#655B22",
  },
  silver: {
    bg: "#9E9FA3",
    fill: "#aaabaf",
    border: "#787B7A",
    icon: "#E2E3E5",
  },
}

const MUSEUM_CARD_COLORS = {
  insect: {
    bg: "#b7f5b2",
    border: "#edffeb",
    icon: "#2F5A26",
    line: "#91d999",
    fill: "#7dce87",
  },
  fish: {
    bg: "#9dfadf",
    border: "#d2ffe6",
    icon: "#30696a",
    line: "#85d9dc",
    fill: "#65cfd2",
  },
  seaCreature: {
    bg: "#9dfadf",
    border: "#d2ffe6",
    icon: "#30696a",
    line: "#65cfd2",
    fill: "#85d9dc",
  },
  fossil:{
    bg: "#f2deab",
    border: "#fff3cd",
    icon: "#7b6245",
    line: "#e6bd8b",
    fill: "#eed3a2",
  }
}

const INSECT_LOCATION_TYPES = {
  1: {
    "zh-hans": "空中",
    "zh-hant": "空中",
    en: "Flying",
    ja: "空中",
    ko: "공중",
  },
  2: {
    "zh-hans": "花附近/空中",
    "zh-hant": "花附近/空中",
    en: "Flying near flowers",
    ja: "花/空中",
    ko: "꽃 / 공중",
  },
  3: {
    "zh-hans": "异色花(黒,紫,蓝)附近/空中",
    "zh-hant": "異色花(黑,紫,藍)附近/空中",
    en: "Flying near blue/purple/black flowers",
    ja: "花(黒/紫/青)/空中",
    ko: "꽃 (검정 / 보라색 / 파랑)/공중",
  },
  4:{
    "zh-hans": "户外灯光附近/空中",
    "zh-hant": "戶外燈光附近/空中",
    en: "Flying near light sources",
    ja: "灯りの近く",
    ko: "광원 근처에서 비행",
  },
  5:{
    "zh-hans": "树干（包括椰子树）",
    "zh-hant": "樹幹（包括椰子樹）",
    en: "On trees (any kind)",
    ja: "トランク（ヤシの木を含む）",
    ko: "트렁크 (야자수 포함)",
  },
  6:{
    "zh-hans": "树干（非椰子树）",
    "zh-hant": "樹幹（非椰子樹）",
    en: "On hardwood/cedar trees",
    ja: "トランク（ココナッツの木を除く",
    ko: "트렁크 (코코넛 트리 제외)",
  },
  7:{
    "zh-hans": "树干（仅椰子树）",
    "zh-hant": "樹幹（僅椰子樹）",
    en: "On palm trees",
    ja: "トランク（ヤシの木）",
    ko: "트렁크 (야자 나무)",
  },
  8:{
    "zh-hans": "地面/跳跃",
    "zh-hant": "地面/跳躍",
    en: "On the ground(leap)",
    ja: "地上(ジャンプ)",
    ko: "지상에 (도약)",
  },
  9:{
    "zh-hans": "地面/爬行",
    "zh-hant": "地面/爬行",
    en: "On the ground(crawl)",
    ja: "地上(クロール)",
    ko: "지상에 (포복)",
  },
  10:{
    "zh-hans": "花朵上",
    "zh-hant": "花朵上",
    en: "On flowers",
    ja: "花の上",
    ko: "꽃에",
  },
  11:{
    "zh-hans": "白色花朵上",
    "zh-hant": "白色花朵上",
    en: "On white flowers",
    ja: "白い花に",
    ko: "흰 꽃에",
  },
  12:{
    "zh-hans": "树上（非椰子树）摇晃",
    "zh-hant": "樹上（非椰子樹）搖晃",
    en: "Shaking trees (hardwood or cedar only)",
    ja: "木を揺する (ココナッツの木を除く)",
    ko: "나무를 동요 (코코넛 트리 제외)",
  },
  13:{
    "zh-hans": "水边/空中",
    "zh-hant": "水邊/空中",
    en: "Flying near water",
    ja: "水の近くを飛ぶ",
    ko: "물 근처에서 비행",
  },
  14:{
    "zh-hans": "地下（辨音挖掘）",
    "zh-hant": "地下（辨音挖掘）",
    en: "Underground (dig where noise is loudest)",
    ja: "地中(掘ると出現)",
    ko: "지중 (파 출현)",
  },
  15:{
    "zh-hans": "水面（河流及池塘）",
    "zh-hant": "水面（河流及池塘）",
    en: "On rivers/ponds",
    ja: "水面(川/池)",
    ko: "수면(강 / 연못에서)",
  },
  16:{
    "zh-hans": "树桩上",
    "zh-hant": "樹樁上",
    en: "On tree stumps",
    ja: "切り株の上",
    ko: "그루터기에",
  },
  17:{
    "zh-hans": "雪球附近",
    "zh-hant": "雪球附近",
    en: "Pushing snowballs",
    ja: "雪玉を転がしている",
    ko: "눈 뭉치를 굴려있다",
  },
  18:{
    "zh-hans": "地面（拟态成家具状叶子）",
    "zh-hant": "地面（擬態成家具狀葉子）",
    en: "Disguised under trees",
    ja: "家具マークに擬態",
    ko: "나무 아래 위장",
  },
  19:{
    "zh-hans": "地面（坏掉的大头菜附近）",
    "zh-hant": "地面（壞掉的大頭菜附近）",
    en: "On rotten turnips",
    ja: "地上(壊れたコールラビの近く)",
    ko: "지상 (브로큰 콜라 비 근처)",
  },
  20:{
    "zh-hans": "沙滩（拟态成贝壳）",
    "zh-hant": "沙灘（擬態成貝殼）",
    en: "Disguised on shoreline",
    ja: "地上(貝がらに擬態)",
    ko: "지상 (해안선에 위장)",
  },
  21:{
    "zh-hans": "沙滩（礁石上爬行）",
    "zh-hant": "沙灘（礁石上爬行）",
    en: "On beach rocks",
    ja: "岩礁(海岸の岩)",
    ko: "록키 리프 (해안)",
  },
  22:{
    "zh-hans": "地面放置的垃圾（轮胎、长靴及空罐）或坏掉的大头菜附近/空中",
    "zh-hant": "地面放置的垃圾（輪胎、長靴及空罐）或壞掉的大頭菜附近/空中",
    en: "Flying near trash (boots, tires, cans) or rotten turnips",
    ja: "ゴミ（ブーツ、タイヤ、缶）や腐ったカブの近くを飛ぶ",
    ko: "쓰레기 (부츠, 타이어, 캔) 또는 썩은 순무 근처에서 비행",
  },
  23:{
    "zh-hans": "岛内居民身上（跳跃）",
    "zh-hant": "島內居民身上（跳躍）",
    en: "On villagers",
    ja: "住民の体",
    ko: "섬 주민",
  },
  24:{
    "zh-hans": "岩石或灌木上（爬行）",
    "zh-hant": "岩石或灌木上（爬行）",
    en: "On rocks/bushes",
    ja: "岩/茂みの上",
    ko: "바위 / 나무 위에",
  },
  25:{
    "zh-hans": "岩石中（敲击）",
    "zh-hant": "岩石中（敲擊）",
    en: "From hitting rocks",
    ja: "石を叩くと出現",
    ko: "돌을 두드리면 출현",
  },
}

const INSECT_WEATHER_TYPES = {
  1: {
    "zh-hans": "任何天气",
    "zh-hant": "任何天氣",
    en: "Any weather",
    ja: "どんな天気",
    ko: "어떤 날씨",
  },
  2: {
    "zh-hans": "非雨雪天",
    "zh-hant": "非雨雪天",
    en: "Any except rain",
    ja: "雨のない日",
    ko: "비오는 날",
  },
  3: {
    "zh-hans": "雨天",
    "zh-hant": "雨天",
    en: "Rain only",
    ja: "雨",
    ko: "비",
  },
}

const FISH_LOCATION_TYPES = {
  1: {
    "zh-hans": "河流",
    "zh-hant": "河流",
    en: "River",
    ja: "川",
    ko: "강",
  },
  2: {
    "zh-hans": "河流（悬崖上）",
    "zh-hant": "河流（懸崖上）",
    en: "River (clifftop)",
    ja: "川（崖の上）",
    ko: "강（절벽에서）",
  },
  3: {
    "zh-hans": "河口",
    "zh-hant": "河口",
    en: "River (mouth)",
    ja: "河口",
    ko: "강어귀",
  },
  4: {
    "zh-hans": "池塘",
    "zh-hant": "池塘",
    en: "Pond",
    ja: "池",
    ko: "못",
  },
  5: {
    "zh-hans": "大海",
    "zh-hant": "大海",
    en: "Sea",
    ja: "海",
    ko: "바다",
  },
  6: {
    "zh-hans": "码头",
    "zh-hant": "碼頭",
    en: "Pier",
    ja: "桟橋",
    ko: "교각",
  },
  7: {
    "zh-hans": "大海（雨天）",
    "zh-hant": "大海（雨天）",
    en: "Sea (rainy days)",
    ja: "海（雨の日）",
    ko: "바다 (비오는 날)",
  }
}

const FISH_SHADOW_TYPES = {
  1: {
    "zh-hans": "特大",
    "zh-hant": "特大",
    en: "XX-Large",
    ja: "特大",
    ko: "특대",
  },
  2: {
    "zh-hans": "特大",
    "zh-hant": "特大",
    en: "X-Large",
    ja: "特大",
    ko: "특대",
  },
  3: {
    "zh-hans": "大",
    "zh-hant": "大",
    en: "Large",
    ja: "大",
    ko: "큰",
  },
  4: {
    "zh-hans": "中",
    "zh-hant": "中",
    en: "Medium",
    ja: "中",
    ko: "매질",
  },
  5: {
    "zh-hans": "小",
    "zh-hant": "小",
    en: "Small",
    ja: "小",
    ko: "작은",
  },
  6: {
    "zh-hans": "极小",
    "zh-hant": "極小",
    en: "X-Small",
    ja: "極小",
    ko: "작은",
  },
  7: {
    "zh-hans": "背鳍",
    "zh-hant": "背鰭",
    en: "Fin(X-Large)",
    ja: "背びれ",
    ko: "등 지느러미",
  },
  8: {
    "zh-hans": "细长",
    "zh-hant": "細長",
    en: "Long",
    ja: "細長",
    ko: "날씬한",
  },
}

const MOVEMENT_SPEED_TYPES = {
  1: {
    "zh-hans": "特快",
    "zh-hant": "特快",
    en: "Very Fast",
    ja: "高速",
    ko: "매우 빠름",
  },
  2: {
    "zh-hans": "快",
    "zh-hant": "快",
    en: "Fast",
    ja: "速い",
    ko: "빠른",
  },
  3: {
    "zh-hans": "普通",
    "zh-hant": "普通",
    en: "Medium",
    ja: "普通",
    ko: "보통주",
  },
  4: {
    "zh-hans": "慢",
    "zh-hant": "慢",
    en: "Slow",
    ja: "遅い",
    ko: "느린",
  },
  5: {
    "zh-hans": "极慢",
    "zh-hant": "極慢",
    en: "Very Slow",
    ja: "非常に遅い",
    ko: "매우 느림",
  },
  6: {
    "zh-hans": "不动",
    "zh-hant": "不動",
    en: "Stationary",
    ja: "動かない",
    ko: "움직이지 않는",
  },
}

const FISH_LIGHTING_TYPES = {
  0: {
    "zh-hans": "沒有照明",
    "zh-hant": "沒有照明",
    en: "No lighting",
    ja: "照明なし",
    ko: "조명 없음",
  },
  1: {
    "zh-hans": "荧光",
    "zh-hant": "熒光",
    en: "Fluorescent",
    ja: "蛍光",
    ko: "형광",
  },
  2: {
    "zh-hans": "射出灯光",
    "zh-hant": "射出燈光",
    en: "Emission",
    ja: "プロジェクトライト",
    ko: "프로젝트 조명",
  },
}

const ALBUM_SECRET_TYPES = {
  0: {
    en: "Common song",
  },
  1: {
    en: "Hidden song - only by request",
  },
  2: {
    en: "Received on your first time seeing KK perform during your birthday",
  },
  3: {
    en: "Received on your first time seeing KK perform",
  },
}

const VILLAGER_SPECIES_TYPES = {
  1: {
    en: "Hamster",
    ja: "ハムスター",
    ko: "햄스터",
    "zh-hans": "仓鼠",
    "zh-hant": "倉鼠",
  },
  2: {
    en: "Mouse",
    ja: "マウス",
    ko: "생쥐",
    "zh-hans": "老鼠",
    "zh-hant": "老鼠",
  },
  3: {
    en: "Squirrel",
    ja: "リス",
    ko: "다람쥐",
    "zh-hans": "松鼠",
    "zh-hant": "松鼠",
  },
  4: {
    en: "Kangaroo",
    ja: "カンガルー",
    ko: "캥거루",
    "zh-hans": "袋鼠",
    "zh-hant": "袋鼠",
  },
  5: {
    en: "Rabbit",
    ja: "ウサギ",
    ko: "토끼",
    "zh-hans": "兔子",
    "zh-hant": "兔子",
  },
  6: {
    en: "Anteater",
    ja: "アリクイ",
    ko: "개미핥기",
    "zh-hans": "食蚁兽",
    "zh-hant": "食蟻獸",
  },
  7: {
    en: "Chicken",
    ja: "ニワトリ",
    ko: "닭",
    "zh-hans": "鸡",
    "zh-hant": "雞",
  },
  8: {
    en: "Duck",
    ja: "アヒル",
    ko: "오리",
    "zh-hans": "鸭子",
    "zh-hant": "鴨子",
  },
  9: {
    en: "Cat",
    ja: "ネコ",
    ko: "고양이",
    "zh-hans": "猫",
    "zh-hant": "貓",
  },
  10: {
    en: "Dog",
    ja: "イヌ",
    ko: "개",
    "zh-hans": "狗",
    "zh-hant": "狗",
  },
  11: {
    en: "Pig",
    ja: "ブタ",
    ko: "돼지",
    "zh-hans": "猪",
    "zh-hant": "豬",
  },
  12: {
    en: "Goat",
    ja: "ヤギ",
    ko: "염소",
    "zh-hans": "山羊",
    "zh-hant": "山羊",
  },
  13: {
    en: "Sheep",
    ja: "ヒツジ",
    ko: "양",
    "zh-hans": "绵羊",
    "zh-hant": "綿羊",
  },
  14: {
    en: "Bull",
    ja: "オウシ",
    ko: "수소",
    "zh-hans": "公牛",
    "zh-hant": "公牛",
  },
  15: {
    en: "Cow",
    ja: "メウシ",
    ko: "암소",
    "zh-hans": "母牛",
    "zh-hant": "母牛",
  },
  16: {
    en: "Horse",
    ja: "ウマ",
    ko: "말",
    "zh-hans": "马",
    "zh-hant": "馬",
  },
  17: {
    en: "Koala",
    ja: "コアラ",
    ko: "코알라",
    "zh-hans": "树袋熊",
    "zh-hant": "無尾熊",
  },
  18: {
    en: "Bear Cub",
    ja: "コグマ",
    ko: "꼬마곰",
    "zh-hans": "小熊",
    "zh-hant": "小熊",
  },
  19: {
    en: "Bear",
    ja: "クマ",
    ko: "곰",
    "zh-hans": "熊",
    "zh-hant": "熊",
  },
  20: {
    en: "Monkey",
    ja: "サル",
    ko: "원숭이",
    "zh-hans": "猴子",
    "zh-hant": "猴子",
  },
  21: {
    en: "Gorilla",
    ja: "ゴリラ",
    ko: "고릴라",
    "zh-hans": "猩猩",
    "zh-hant": "猩猩",
  },
  22: {
    en: "Bird",
    ja: "トリ",
    ko: "새",
    "zh-hans": "鸟",
    "zh-hant": "鳥",
  },
  23: {
    en: "Eagle",
    ja: "ワシ",
    ko: "독수리",
    "zh-hans": "雕",
    "zh-hant": "鵰",
  },
  24: {
    en: "Ostrich",
    ja: "ダチョウ",
    ko: "타조",
    "zh-hans": "鸵鸟",
    "zh-hant": "鴕鳥",
  },
  25: {
    en: "Deer",
    ja: "コジカ",
    ko: "사슴",
    "zh-hans": "小鹿",
    "zh-hant": "小鹿",
  },
  26: {
    en: "Wolf",
    ja: "オオカミ",
    ko: "늑대",
    "zh-hans": "狼",
    "zh-hant": "狼",
  },
  27: {
    en: "Tiger",
    ja: "トラ",
    ko: "호랑이",
    "zh-hans": "老虎",
    "zh-hant": "老虎",
  },
  28: {
    en: "Lion",
    ja: "ライオン",
    ko: "사자",
    "zh-hans": "狮子",
    "zh-hant": "獅子",
  },
  29: {
    en: "Rhinoceros",
    ja: "サイ",
    ko: "코뿔소",
    "zh-hans": "犀牛",
    "zh-hant": "犀牛",
  },
  30: {
    en: "Elephant",
    ja: "ゾウ",
    ko: "코끼리",
    "zh-hans": "大象",
    "zh-hant": "大象",
  },
  31: {
    en: "Frog",
    ja: "カエル",
    ko: "개구리",
    "zh-hans": "青蛙",
    "zh-hant": "青蛙",
  },
  32: {
    en: "Penguin",
    ja: "ペンギン",
    ko: "펭귄",
    "zh-hans": "企鹅",
    "zh-hant": "企鵝",
  },
  33: {
    en: "Octopus",
    ja: "タコ",
    ko: "문어",
    "zh-hans": "章鱼",
    "zh-hant": "章魚",
  },
  34: {
    en: "Alligator",
    ja: "ワニ",
    ko: "악어",
    "zh-hans": "鳄鱼",
    "zh-hant": "鱷魚",
  },
  35: {
    en: "Hippo",
    ja: "カバ",
    ko: "하마",
    "zh-hans": "河马",
    "zh-hant": "河馬",
  },
}

const VILLAGER_PERSONALITY_TYPES = {
  1: {
    en: "Peppy",
    ja: "元気",
    ko: "아이돌",
    "zh-hans": "元气",
    "zh-hant": "元氣",
  },
  2: {
    en: "Smug",
    ja: "キザ",
    ko: "느끼함",
    "zh-hans": "自恋",
    "zh-hant": "自戀",
  },
  3: {
    en: "Jock",
    ja: "ハキハキ",
    ko: "운동광",
    "zh-hans": "运动",
    "zh-hant": "運動",
  },
  4: {
    en: "Lazy",
    ja: "ぼんやり",
    ko: "먹보",
    "zh-hans": "悠闲",
    "zh-hant": "悠閒",
  },
  5: {
    en: "Cranky",
    ja: "コワイ",
    ko: "무뚝뚝",
    "zh-hans": "暴躁",
    "zh-hant": "暴躁",
  },
  6: {
    en: "Big Sister",
    ja: "アネキ",
    ko: "단순활발",
    "zh-hans": "大姐姐",
    "zh-hant": "大姐姐",
  },
  7: {
    en: "Normal",
    ja: "普通",
    ko: "친절함",
    "zh-hans": "普通",
    "zh-hant": "普通",
  },
  8: {
    en: "Snooty",
    ja: "オトナ",
    ko: "성숙함",
    "zh-hans": "成熟",
    "zh-hant": "成熟",
  },
}

const VILLAGER_HOBBY_TYPES = {
  1: {
    en: "Play",
    ja: "遊び",
    ko: "놀기",
    "zh-hans": "游戏",
    "zh-hant": "遊戲",
  },
  2: {
    en: "Music",
    ja: "音楽",
    ko: "음악",
    "zh-hans": "音乐",
    "zh-hant": "音樂",
  },
  3: {
    en: "Fitness",
    ja: "運動",
    ko: "운동",
    "zh-hans": "健身",
    "zh-hant": "健身",
  },
  4: {
    en: "Education",
    ja: "教育",
    ko: "교육",
    "zh-hans": "教育",
    "zh-hant": "教育",
  },
  5: {
    en: "Fashion",
    ja: "ファッション",
    ko: "패션",
    "zh-hans": "时尚",
    "zh-hant": "時尚",
  },
  6: {
    en: "Nature",
    ja: "自然",
    ko: "자연",
    "zh-hans": "自然",
    "zh-hant": "自然",
  },
}

const VILLAGER_STYLE_TYPES = {
  1: {
    en: "simple",
    "zh-hans": "简单",
  },
  2: {
    en: "cute",
    "zh-hans": "可爱",
  },
  3: {
    en: "elegant",
    "zh-hans": "优雅",
  },
  4: {
    en: "gorgeous",
    "zh-hans": "绚丽",
  },
  5: {
    en: "active",
    "zh-hans": "活跃",
  },
  6: {
    en: "cool",
    "zh-hans": "酷",
  },
}

const VILLAGER_COLOR_TYPES = {
  1: {
    en: "yellow",
    "zh-hans": "黄色",
  },
  2: {
    en: "beige",
    "zh-hans": "米色",
  },
  3: {
    en: "light blue",
    "zh-hans": "浅蓝色",
  },
  4: {
    en: "blue",
    "zh-hans": "蓝色",
  },
  5: {
    en: "orange",
    "zh-hans": "橙色",
  },
  6: {
    en: "purple",
    "zh-hans": "紫色",
  },
  7: {
    en: "green",
    "zh-hans": "绿色",
  },
  8: {
    en: "red",
    "zh-hans": "红色",
  },
  9: {
    en: "pink",
    "zh-hans": "粉色",
  },
  10: {
    en: "black",
    "zh-hans": "黑色",
  },
  11: {
    en: "gray",
    "zh-hans": "灰色",
  },
  12: {
    en: "brown",
    "zh-hans": "棕色",
  },
  13: {
    en: "white",
    "zh-hans": "白色",
  },
  14: {
    en: "colorful",
    "zh-hans": "彩色",
  },
}

const VILLAGER_GENDER_TYPES = {
  1: {
    en: "female",
    "zh-hans": "女孩",
  },
  2: {
    en: "male",
    "zh-hans": "男孩",
  },
}

const VILLAGER_VOLUME_TYPES = {
  1: {
    en: "low",
    "zh-hans": "低",
  },
  2: {
    en: "medium",
    "zh-hans": "中",
  },
  3: {
    en: "high",
    "zh-hans": "高",
  },
}

const SEASON_EVENTS = {
  1: {
    en: "Mom's Gifts",
    "zh-hans": "妈妈的礼物",
    "zh-hant": "媽媽的禮物",
    ja: "ははの贈りも",
    ko: "엄마의 선물"
  },
  2:{
    en: "Spring",
    "zh-hans": "春季",
    "zh-hant": "春季",
    ja: "はる春",
    ko: "봄"
  },
  3:{
    en: "Easter Day",
    "zh-hans": "复活节",
    "zh-hant": "復活節",
    ja: "イースター",
    ko: "이스터"
  },
  4:{
    en: "Cherry Blossom Season",
    "zh-hans": "樱花季",
    "zh-hant": "櫻花季",
    ja: "桜の季節",
    ko: "벚꽃 시즌"
  },
  5:{
    en: "Fishing Tourney",
    "zh-hans": "钓鱼大赛",
    "zh-hant": "釣魚大會",
    ja: "つ釣りたいかい大会",
    ko: "낚시대회"
  },
  6:{
    en: "Labour Day",
    "zh-hans": "劳动节",
    "zh-hant": "勞動節",
    ja: "労働者の日",
    ko: "노동절"
  },
  7:{
    en: "Mother's Day",
    "zh-hans": "母亲节",
    "zh-hant": "母親節",
    ja: "はは母のひ日",
    ko: "어머니날"
  },
  8:{
    en: "International Museum Day",
    "zh-hans": "国际博物馆日",
    "zh-hant": "國際博物館日",
    ja: "国際博物館の日",
    ko: "국제 박물관의 날"
  },
  9:{
    en: "Summer",
    "zh-hans": "夏季",
    "zh-hant": "夏季",
    ja: "なつ夏",
    ko: "여름"
  },
  10:{
    en: "International Children's Day",
    "zh-hans": "国际儿童节",
    "zh-hant": "國際兒童節",
    ja: "こくさい国際こどものひ日",
    ko: "국제 어린이날"
  },
  11:{
    en: "Father's Day",
    "zh-hans": "父亲节",
    "zh-hant": "世界父親節",
    ja: "ちち父のひ日",
    ko: "아버지날"
  },
  12:{
    en: "Wedding Season",
    "zh-hans": "婚礼季",
    "zh-hant": "婚禮季",
    ja: "ジューンブライド",
    ko: "웨딩 시즌"
  },
  13:{
    en: "Summer Solstice",
    "zh-hans": "夏至祭典",
    "zh-hant": "夏至祭典",
    ja: "げしさい夏至祭",
    ko: "하지"
  },
  14:{
    en: "Bug-Off",
    "zh-hans": "捕虫大赛",
    "zh-hant": "捕蟲大會",
    ja: "ムシとりたいかい大会",
    ko: "곤충채집대회"
  },
  15:{
    en: "Tanabata",
    "zh-hans": "日本七夕",
    "zh-hant": "日本七夕",
    ja: "たなばた",
    ko: "일본 칠석"
  },
  16:{
    en: "Fireworks Shows",
    "zh-hans": "烟花大会",
    "zh-hant": "煙火大會",
    ja: "はなびたいかい",
    ko: "불꽃축제"
  },
  17:{
    en: "Cowboy Festival",
    "zh-hans": "牛仔节",
    "zh-hant": "牛仔節",
    ja: "カウボーイまつ祭り",
    ko: "카우보이 축제"
  },
  18:{
    en: "Cowherd & Weaver Girl Day",
    "zh-hans": "七夕",
    "zh-hant": "七夕",
    ja: "ぎゅうろうしょくじょ牛郎織女のひ日",
    ko: "칠석"
  },
  19:{
    en: "Fall",
    "zh-hans": "秋季",
    "zh-hant": "秋季",
    ja: "あき秋",
    ko: "가을"
  },
  20:{
    en: "Grape Harvest Festival",
    "zh-hans": "葡萄丰收节",
    "zh-hant": "葡萄豐收節",
    ja: "ブドウのしゅうかくさい収穫祭",
    ko: "포도 수확제"
  },
  21:{
    en: "Moon-Viewing Day",
    "zh-hans": "中秋节",
    "zh-hant": "中秋節",
    ja: "おつきみ月見",
    ko: "추석"
  },
  22:{
    en: "Halloween",
    "zh-hans": "万圣节",
    "zh-hant": "萬聖節",
    ja: "ハロウィーン",
    ko: "할로윈"
  },
  23:{
    en: "Mush Season",
    "zh-hans": "蘑菇季",
    "zh-hant": "蘑菇季",
    ja: "キノコの季節",
    ko: "버섯 시즌"
  },
  24:{
    en: "Maple Leaf Season",
    "zh-hans": "红叶季",
    "zh-hant": "楓葉季",
    ja: "紅葉の季節",
    ko: "단풍잎 시즌"
  },
  25:{
    en: "Thanksgiving Day",
    "zh-hans": "感恩节",
    "zh-hant": "感恩節",
    ja: "かんしゃさい",
    ko: "추수감사절"
  },
  26:{
    en: "Winter",
    "zh-hans": "冬季",
    "zh-hant": "冬季",
    ja: "ふゆ冬",
    ko: "겨울"
  },
  27:{
    en: "Winter Solstice",
    "zh-hans": "冬至祭典",
    "zh-hant": "冬至祭典",
    ja: "とうじさい冬至祭",
    ko: "동지"
  },
  28:{
    en: "Christmas",
    "zh-hans": "圣诞节",
    "zh-hant": "聖誕節",
    ja: "クリスマス",
    ko: "크리스마스"
  },
  29:{
    en: "New Year's Eve",
    "zh-hans": "跨年夜",
    "zh-hant": "跨年夜",
    ja: "おおみそか大晦日",
    ko: "오미소카"
  },
  30:{
    en: "Birthday",
    "zh-hans": "生日",
    "zh-hant": "生日",
    ja: "バースデー",
    ko: "생일"
  },
  // 23:{
  //   en: "Lunar New Year",
  //   "zh-hans": "春节",
  //   "zh-hant": "春節",
  //   ja: "しょうがつ",
  //   ko: "설날"
  // }
} 

// 使用 CommonJS 兼容 gatsby-node
module.exports = {
  CSS_VAR_PREFIX,
  INIT_COLOR_MODE_PROP,
  LS_I18N_LNG_KEY,
  LS_COLOR_MODE_KEY,
  DEFAULT_LNG,
  I18N_LANGUAGES,
  SPEC_LNGS_MAP,
  SHORT_HAND_LNGS,
  DIY_CARD_COLORS,
  MUSEUM_CARD_COLORS,
  INSECT_LOCATION_TYPES,
  INSECT_WEATHER_TYPES,
  FISH_LOCATION_TYPES,
  FISH_SHADOW_TYPES,
  MOVEMENT_SPEED_TYPES,
  FISH_LIGHTING_TYPES,
  ALBUM_SECRET_TYPES,
  VILLAGER_HOBBY_TYPES,
  VILLAGER_SPECIES_TYPES,
  VILLAGER_PERSONALITY_TYPES,
  VILLAGER_GENDER_TYPES,
  VILLAGER_STYLE_TYPES,
  VILLAGER_COLOR_TYPES,
  VILLAGER_VOLUME_TYPES,
  SEASON_EVENTS,
}
