export default {
  // 主色调
  text:{
    // 强调色（ 用于标题 ）
    accent: "#f281c3",
    // green
    // accent: "#02c200",
    // blue
    // accent: "#0bb6cc",
    // orange
    // accent: "#f28c00",
    // 主要色（ 用于主体文字 ）
    primary: "#6b5c43",
    // 修饰色（ 用于次要文字，包括标题说明、图片说明等 ）
    muted: "#a58d66",
    // lighten
    // muted: "#978558",
    // muted:"#9b917b"
    // muted:"#afa693"
  },
  background:{
    // 强调色
    // accent: "#fdcc02",
    // 主要色（ 用于主体背景 ）
    primary: "#e5dfc2",
    // 修饰色（ 用于次要背景，包括弹出层等 ）
    muted: "#f4f7e1",
    // 块背景色
    section: "#e6e8d3",
    // 选择栏高亮色
    picker: "#f7cd63"
  },
  modes:{
    dark: {
      // 主色调
      text:{
        // 强调色（ 用于标题 ）
        accent: "#f281c6",
        // blue
        // accent: "#10aec6",
        // orange
        // accent: "#f28f05",
        // 主要色（ 用于主体文字 ）
        primary: "#d4faf9",
        // 修饰色（ 用于次要文字，包括标题说明、图片说明等 ）
        muted: "#8ba6a4",
        // lighten
        // muted: "#a6cad0",
        // muted: "#9fbfc8"
        // muted:"#7798a3"
      },
      // 博客背景配色
      background:{
        // 强调色
        // accent: "#ffb800",
        // 主要色（ 用于主体背景 ）
        primary: "#1d3752",
        // 修饰色（ 用于次要背景，包括弹出层等 ）
        muted: "#2f495d",
        // 块背景色
        section: "#345874",
        // 选择栏高亮色
        picker: "#5881ff"
      }
    }
  }
}